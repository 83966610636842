<template>
  <div>
    <el-dialog
      title="新建图层分组"
      :visible.sync="dialogVisible"
      :inline="true"
      :close-on-click-modal="false"
      width="356px"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form ref="form" :model="form" size="mini">
        <el-form-item label="图层分组名称：">
          <el-input v-model="form.name" placeholder="请输入管线图层组名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button size="mini">取消</el-button>
        <el-button type="primary" size="mini">保存</el-button>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      dialogVisible: true
    }
  },
  mounted() {},
  methods: {
    close() {
      console.log('关闭呀')
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 17px 15px 0 15px;
  }
}
</style>
